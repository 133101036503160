.purple {
  color: rgb(88, 86, 214);
}

.bg-purple {
  background-color: rgb(88, 86, 214);
}

.shadow {
  box-shadow: rgba(66, 66, 66, 0.5) 0px 4px 20px 0px,
    rgba(0, 0, 0, 0.0666) 0px 1px 1px 0px;
}

.pv05 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.man1 {
  margin: -0.25rem;
}

.h05 {
  height: 0.5rem;
}

.w05 {
  width: 0.5rem;
}

.BaseTable {
  box-shadow: none !important;
}

.BaseTable__table {
  outline: none !important;
}

.focus-no-outline:focus {
  outline: none;
}

.no-outline {
  outline: none;
}

.loading-ring,
.loading-ring-large {
  display: inline-block;
}
.loading-ring:after,
.loading-ring-large:after {
  content: " ";
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  border: 0.125rem solid #aaa;
  border-color: #aaa #aaa transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.loading-ring-large:after {
  width: 1.5rem;
  height: 1.5rem;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pie {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.pie > button {
  outline: none;
  border: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  bottom: 50%;
  transform-origin: bottom left;
}

.cursor-text {
  cursor: text;
}

.cursor-zoom-in {
  cursor: zoom-in;
}

.mw5-5 {
  max-width: 24rem;
}

.button-shadow {
  box-shadow: #ccc 0px 2px 0px 0px;
}

@media (prefers-color-scheme: dark) {
  html {
    background-color: #090909;
    color: #eee;
  }

  .bg-white {
    background-color: #090909;
  }
  .bg-white-70 {
    background-color: rgba(0, 0, 0, 0.9);
  }
  .bg-near-white {
    background-color: #111;
  }
  .bg-light-gray {
    background-color: #222;
  }
  .bg-purple {
    background-color: rgb(94, 92, 230);
  }
  .black {
    color: #fff;
  }
  .near-black {
    color: #eee;
  }
  .dark-gray {
    color: #ccc;
  }
  .mid-gray {
    color: #aaa;
  }
  .purple {
    color: rgb(94, 92, 230);
  }
  .hover-near-black:hover {
    color: #eee;
  }
  .b--light-gray {
    border-color: #222;
  }
  .b--moon-gray {
    border-color: #333;
  }
  .shadow {
    box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 20px 0px,
      rgba(0, 0, 0, 0.0666) 0px 1px 1px 0px;
  }
  .button-shadow {
    box-shadow: #333 0px 2px 0px 0px;
  }
  .tooltip-triangle path {
    fill: #090909;
  }

  .BaseTable {
    background-color: #090909;
  }
  .BaseTable__empty-layer {
    background-color: #090909;
  }
  .BaseTable__table {
    background-color: #090909;
  }
  .BaseTable__row--customized,
  .BaseTable__header-row {
    border-color: #222;
  }
  .react-select__control {
    background-color: #222222 !important;
  }

  .react-select__input,
  .react-select__single-value {
    color: #eee !important;
  }

  .react-select__indicator {
    color: #ccc !important;
  }

  .react-select__indicator:hover {
    color: #eee !important;
  }

  .react-select__menu {
    background-color: #333333 !important;
  }

  .react-select__option--is-focused {
    background-color: #555555 !important;
  }
}

.show-on-hover {
}

.show-on-hover--content {
  visibility: hidden;
}

.show-on-hover:hover .show-on-hover--content {
  visibility: visible;
}

@media screen and (min-width: 60em) {
  .map-sidebar {
    min-width: 24rem;
    max-width: 24rem;
  }
}
